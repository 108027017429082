import uniq from 'lodash/uniq';
import isEqual from 'lodash/isEqual';
import omit from 'lodash/omit';
import pick from 'lodash/pick';
import partition from 'lodash/partition';
import flatten from 'lodash/flatten';
import createMapWithKeys from '@/utils/createMapWithKeys';
import getSessionInterview from '@/services/getSessionInterview';
import { Status, ValuesMap, VerificationResult } from './interfaces/IdoState';
import RawIdoActions from './interfaces/RawIdoActions';
import ErrorObject from './interfaces/ErrorObject';
import load from '@evidentid/browser-framework/loader';
import {
    getAcademicProviders,
    getUtilityProviders,
    notifyDelegate,
    requestTransition,
    sendConsent,
    submitPayment,
    submitWebAttributes,
    tearDown,
    undoSetUp,
    verifyDocumentImages,
} from '@evidentid/ido-lib/idoWebClient';
import delay from '@evidentid/universal-framework/delay';
import getField from '@/fields/getField';
import { maxDelegationsPerAttribute } from '@/config/phoneDelegations';
import getCriminalOffensesList from '@/services/getCriminalOffensesList';
import redirect from '@evidentid/vue-commons/router/redirect';
import findQuestionsForAttributeTypes from '@/utils/findQuestionsForAttributeTypes';
import extractAttributeTypesFromValues from '@/utils/extractAttributeTypesFromValues';
import buildPreflightAttributesValues from '@/utils/buildPreflightAttributesValues';
import * as verificationConfig from '@/config/attributesVerification';
import getVerificationFailureReason from '@/utils/getVerificationFailureReason';
import Field from '@/fields/Field';
import { isGlsSession } from '@/interfaces/SessionData';
import getGlsSessionInterview from '@/services/getGlsSessionInterview';
import glsApiClient from '@/glsApiClient';
import { GlsAttributeType } from '@/config/gls';
import { createActionFactories } from './mutations';

function isCsrfTokenError(error: { reason?: string, message?: string } | null): boolean {
    return Boolean(
        error &&
        'message' in error &&
        `${error.message}`.includes('CSRF session')
    );
}

function isAuthError(error: { reason?: string, message?: string } | null): boolean {
    if (!error) {
        return false;
    }
    const reason = 'reason' in error ? error.reason : error.message;
    return `${reason}`.startsWith('auth/');
}

async function getGlsTransition(): Promise<{ transitionUri: string | null, status: string }> {
    const { interviewUrl } = await glsApiClient.getSession();
    return {
        status: interviewUrl ? 'transitionReady' : 'transitionNotNeeded',
        transitionUri: interviewUrl,
    };
}

function emailAlreadyExists(GlsAttributeType: Record<string, string>, values: ValuesMap, newEmail: string): boolean {
    const allEmails = flatten(Object.values(GlsAttributeType).map((key: string) => values[key]?.list || []));
    return allEmails.some((e) => e.toLowerCase() === newEmail.toLowerCase());
}

function errorContainsText(error: string | { error: string } | null): boolean {
    if (!error) {
        return false;
    }
    if (typeof error === 'string') {
        return Boolean(error.trim().length);
    }
    return Boolean(error.error && (error.error).trim().length);
}

const factories = createActionFactories<RawIdoActions>(() => ({
    async showError(error) {
        const finalError: ErrorObject | { message: string } | null
            = typeof error === 'string' ? { reason: error } : error;
        const reason = (finalError && 'reason' in finalError && finalError.reason) || null;

        // Handle missing CSRF token as 'unauthorized-ido' error
        if (isCsrfTokenError(finalError)) {
            this.mutations.setError({ reason: 'unauthorized-ido' });
            return;
        }

        // Handle all authentication problems as 'unauthorized-id' error
        if (isAuthError(finalError)) {
            this.mutations.setError({ reason: 'unauthorized-ido' });
            return;
        }

        // Handle authorization errors in GLS flow
        const session = this.state.session;
        if (session && isGlsSession(session)) {
            const isUnauthorized = reason === 'gls/unauthorized';
            const isSessionExpired = reason === 'gls/session-expired';

            if (isSessionExpired) {
                await this.actions.displaySnackbar({
                    success: false,
                    message: 'Your session has expired, please log in again.',
                });
                await this.actions.logOut({ localOnly: true });
            }

            if (isSessionExpired || isUnauthorized) {
                await this.actions.redirectLocally({
                    name: 'google-business-verification-v2',
                    params: { caseId: `${session.caseId}` },
                });
                return;
            }
        }

        // Other errors should be passed down
        this.mutations.setError(finalError || null);
    },

    displaySnackbar(configuration) {
        this.mutations.setSnackbarContent(
            configuration
                ? { success: false, permanent: false, message: null, ...configuration }
                : null
        );
    },

    setSessionData(sessionData) {
        this.mutations.setUserSession(sessionData);
    },

    async loadInterview(sessionData) {
        this.mutations.setStatus(Status.loading);

        // Handle empty session data
        if (!sessionData) {
            await this.actions.showError('unknown-request');
            this.mutations.setStatus(Status.error);
            this.mutations.setInterviewDetails(null);
            return;
        }

        // Initialize data loading
        this.mutations.setUserSession(sessionData);

        // Load and set-up all data
        try {
            const rawSessionInterview = isGlsSession(sessionData)
                ? await getGlsSessionInterview(sessionData)
                : await getSessionInterview(sessionData);
            const sessionInterview = omit(rawSessionInterview, [ 'gls' ]);
            const hasPaymentRequired = parseFloat(`${sessionInterview.balance?.amount}`) > 0;
            const attributeTypes = sessionInterview.questions
                .filter((question) => !question.complete)
                .map((question) => question.attrType);

            // Set-up GLS details
            this.mutations.setGlsDetails(rawSessionInterview.gls || null);

            // Set-up interview data
            this.mutations.setInterviewDetails(sessionInterview);
            this.mutations.setStatus(Status.success);

            // Set-up initial values
            if (sessionInterview.values && Object.keys(sessionInterview.values).length > 0) {
                this.mutations.setValuesOptionally(sessionInterview.values);
            }

            // Prefetch (asynchronously) additional attributes data for not completed questions
            if (attributeTypes.length > 0) {
                this.actions.fetchAttributeData(attributeTypes);
            }

            // Initialize Stripe payment provider
            if (hasPaymentRequired) {
                await this.actions.initializeStripe();
            }
        } catch (error) {
            this.mutations.setInterviewDetails(null);
            this.mutations.setStatus(Status.error);
            if (isCsrfTokenError(error)) {
                // Show more friendly error when the request is invalid
                await this.actions.showError('unknown-request');
            } else {
                await this.actions.showError(error || 'unexpected');
            }
        }
    },

    async reloadInterview({ blockImmediateFinish }) {
        // Extract data from current app state
        const { session, issuer, user, questions, balance, summary, requestStatus, featureFlags, gls } = this.state;
        const existingAttributeTypes = questions.map((question) => question.attrType);
        const isSubmitting = Object.values(this.state.submission).includes(true);
        const hadPaymentRequired = parseFloat(`${balance?.amount}`) > 0;

        // Ignore when there are no session data available, or submission is in progress
        if (!session || isSubmitting) {
            return;
        }

        try {
            // Load interview details
            const rawSessionInterview = isGlsSession(session)
                ? await getGlsSessionInterview(session, blockImmediateFinish)
                : await getSessionInterview(session, user, issuer, blockImmediateFinish);
            const sessionInterview = omit(rawSessionInterview, [ 'gls' ]);

            // Do not update interview when there were local changes meanwhile
            const isSubmittingAfter = Object.values(this.state.submission).includes(true);
            const hasQuestionsChanges = this.state.questions !== questions;
            if (isSubmittingAfter || hasQuestionsChanges) {
                return;
            }

            // Find differences
            const hasPaymentRequired = parseFloat(`${sessionInterview.balance?.amount}`) > 0;
            const newAttributeTypes = sessionInterview.questions
                .filter((question) => !question.complete)
                .map((question) => question.attrType)
                .filter((attrType) => !existingAttributeTypes.includes(attrType));

            // Update GLS details if expected
            if (!isEqual(gls, rawSessionInterview.gls || null)) {
                this.mutations.setGlsDetails(rawSessionInterview.gls || null);
            }

            // Ignore results, if the previous details are exactly the same. It's mostly to clean up our logs.
            const prevSessionInterview = {
                status: requestStatus,
                user,
                issuer,
                questions,
                summary,
                balance,
                featureFlags,
            };
            if (isEqual(omit(sessionInterview, [ 'values' ]), prevSessionInterview)) {
                // Update values for completed questions if these has changed
                const completedAttrTypes = questions.filter((question) => question.complete).map((x) => x.attrType);
                const prevValues = pick(this.state.values, completedAttrTypes);
                const nextValues = pick(sessionInterview.values || {}, completedAttrTypes);
                if (!isEqual(prevValues, nextValues)) {
                    if (nextValues && Object.keys(nextValues).length > 0) {
                        this.mutations.setValuesOptionally(nextValues);
                    }
                }
                return;
            }

            // Save new interview data
            this.mutations.setInterviewDetails(sessionInterview);
            this.mutations.setStatus(Status.success);

            // Set-up initial/completed values
            if (sessionInterview.values && Object.keys(sessionInterview.values).length > 0) {
                this.mutations.setValuesOptionally(sessionInterview.values);
            }

            // Prefetch (asynchronously) additional attributes data for not completed questions
            if (newAttributeTypes.length > 0) {
                this.actions.fetchAttributeData(newAttributeTypes);
            }

            // Initialize Stripe payment provided, when it's newly required
            if (!hadPaymentRequired && hasPaymentRequired) {
                await this.actions.initializeStripe();
            }
        } catch (error) {
            // Only warn about this error,
            // most likely we don't care about errors
            console.warn('Session interview reload failed', error);
        }
    },

    async logOut({ localOnly }) {
        const { session, questions, gls } = this.state;

        try {
            if (localOnly) {
                await undoSetUp();
            } else if (session && isGlsSession(session)) {
                await Promise.all([
                    tearDown(),
                    glsApiClient.clearSession(),
                ]);
            } else {
                await tearDown();
            }

            // Race condition: don't clear interview when it has already changed
            if (isEqual(this.state.gls, gls) && isEqual(this.state.questions, questions)) {
                this.mutations.setInterviewDetails(null);
                this.mutations.setStatus(Status.loggedOut);
            }

            // Race condition: don't clear the session object when it has already changed
            if (this.state.session === session) {
                this.mutations.setUserSession(null);
            }
        } catch (error) {
            await this.actions.showError(error);
        }
    },

    changeValue(payload) {
        this.mutations.setValue(payload);
    },

    async submitAttributes({ attributes, onUploadProgress }) {
        // Get list of attribute types changed
        const attributeTypes = extractAttributeTypesFromValues(attributes, this.state.questions);

        // Retrieve questions which are sent
        const questions = findQuestionsForAttributeTypes(this.state.questions, attributeTypes);

        // Throw error, when trying to update not existing question only
        if (questions.length === 0) {
            await this.actions.showError('unexpected');
            return;
        }

        // Start fields submission
        this.mutations.setSubmissionStatus(createMapWithKeys(attributeTypes, true));

        try {
            // Submit to back-end
            const request = submitWebAttributes(attributes);

            // Track upload progress with precision up to 0.001 (0.1%)
            if (onUploadProgress) {
                request.onUploadProgressChange(onUploadProgress);
            }

            // Wait for back-end response
            await request;

            // Mark questions as completed
            for (const question of questions) {
                this.mutations.completeQuestion(question.attrType);
            }
        } catch (error) {
            await this.actions.showError('submission-failed');
        }

        // Mark all questions as not in submission process
        this.mutations.setSubmissionStatus(createMapWithKeys(attributeTypes, false));
    },

    async verifyAttributes({ attributes, onUploadProgress }) {
        // Get list of attribute types changed
        const attributeTypes = extractAttributeTypesFromValues(attributes, this.state.questions);

        // Retrieve questions which are sent
        const questions = findQuestionsForAttributeTypes(this.state.questions, attributeTypes);

        // Throw error, when trying to update not existing question only
        if (questions.length === 0) {
            await this.actions.showError('unexpected');
            return;
        }

        // Build preflight attributes
        const preflightAttributesValues = buildPreflightAttributesValues(attributes);

        // Start fields submission
        this.mutations.setSubmissionStatus(createMapWithKeys(attributeTypes, true));
        this.mutations.startVerification({ attributeTypes });

        try {
            // Submit to back-end
            const request = submitWebAttributes(preflightAttributesValues);

            // Track upload progress with precision up to 0.001 (0.1%)
            if (onUploadProgress) {
                request.onUploadProgressChange(onUploadProgress);
            }

            // Wait for back-end response
            const { datumIds } = await request;

            // Mark as uploaded
            this.mutations.setVerificationStatus({
                attributeTypes,
                result: VerificationResult.verifying,
                datumIds,
            });

            // Ask AP first time so it will acknowledge these images
            let response = await verifyDocumentImages(datumIds);
            let resolutionAttempts = 0;

            // Try `maxPollingTimes` or less, until we will receive information about
            while (response.status === 'WAITING' && resolutionAttempts < verificationConfig.maxPollingTimes) {
                // Wait selected time
                await delay(verificationConfig.pollingDelayTimeMs);

                // Ask back-end for verification status
                response = await verifyDocumentImages(datumIds);

                // Register another verification attempt
                resolutionAttempts++;
                this.mutations.attemptVerificationResolution({ attributeTypes });
            }

            // Handle response data
            switch (response.status) {
                case 'WAITING':
                    this.mutations.setVerificationStatus({
                        attributeTypes,
                        result: VerificationResult.timeout,
                    });
                    break;
                case 'FAILED':
                    this.mutations.setVerificationStatus({
                        attributeTypes,
                        result: VerificationResult.failed,
                        failureReason: getVerificationFailureReason(response.reasons),
                    });
                    break;
                case 'OK':
                    this.mutations.setVerificationStatus({
                        attributeTypes,
                        result: VerificationResult.verified,
                    });

                    for (const question of questions) {
                        this.mutations.completeQuestion(question.attrType);
                    }
                    break;
                default:
                    throw new Error('Invalid verification response format');
            }
        } catch (error) {
            this.mutations.setVerificationStatus({
                attributeTypes,
                result: VerificationResult.error,
            });
        }

        // Mark submission as finished
        this.mutations.setSubmissionStatus(createMapWithKeys(attributeTypes, false));
    },

    async fetchAttributeData(attributeTypes) {
        try {
            const questions = findQuestionsForAttributeTypes(this.state.questions, attributeTypes);
            const types = uniq(questions.map((question) => question.type));
            const fields = types.map(getField).filter(Boolean) as Field<any>[];

            await Promise.all(fields.map((field) => field.ready || field.initialize(this)));
        } catch (error) {
            await this.actions.showError(error);
        }
    },

    async loadAcademicProviders() {
        try {
            this.mutations.setAcademicProviders(await getAcademicProviders());
        } catch (error) {
            await this.actions.showError(error);
        }
    },

    async loadUtilityProviders() {
        try {
            this.mutations.setUtilityProviders(await getUtilityProviders());
        } catch (error) {
            await this.actions.showError(error);
        }
    },

    async loadCriminalOffenses() {
        try {
            this.mutations.setCriminalOffenses(await getCriminalOffensesList());
        } catch (error) {
            await this.actions.showError(error);
        }
    },

    setDelegationPhoneNumber(phoneNumber) {
        this.mutations.setDelegationPhoneNumber(phoneNumber);
    },

    async delegate(attrType) {
        const userSession = this.state.session;
        const phoneNumber = this.state.delegationPhoneNumber;
        const delegationStatus = this.state.delegationsRequested[attrType];
        const requestsPerformed = delegationStatus ? delegationStatus.requests : 0;
        const requestsLeft = Math.max(maxDelegationsPerAttribute - requestsPerformed, 0);

        // Validate user session
        if (!userSession) {
            await this.actions.displaySnackbar({
                message: 'We couldn\'t send a text. Please refresh the page.',
                success: false,
            });
            return;
        }

        // GLS sessions shouldn't request delegation, as it is not supported
        if (isGlsSession(userSession)) {
            await this.actions.showError('unexpected');
            return;
        }

        // Validate phone number to send a message
        if (!phoneNumber || !phoneNumber.valid) {
            await this.actions.displaySnackbar({
                message: 'We couldn\'t send a text. Please check your phone number.',
                success: false,
            });
            return;
        }

        // Validate if delegation is still possible
        if (requestsLeft === 0) {
            await this.actions.displaySnackbar({
                message: 'We already sent a text. If you don\'t see one, please refresh the page.',
                success: false,
            });
            return;
        }

        // Delegate to mobile
        this.mutations.startDelegation(attrType);
        try {
            const hash = `#!/interview/${userSession.rprId}`;
            const path = location.pathname.replace(/^\/v\//, '/');
            await notifyDelegate({
                method: 'sms',
                recipient: phoneNumber.qualifiedNumber as string,
                continueUrl: `${location.origin}${path}?q=${attrType}${hash}`,
                enableReturn: false,
            });
            this.mutations.finishDelegation(attrType);
            this.actions.displaySnackbar({
                message: 'Check your phone, we just sent a text.',
                success: true,
            });
        } catch (error) {
            this.mutations.failDelegation(attrType);
            this.actions.displaySnackbar({
                message: 'Uh oh, we couldn\'t send a text. Please try again in a few minutes.',
                success: false,
            });
        }
    },

    async initializeStripe() {
        if (!this.state.stripe) {
            await load('https://js.stripe.com/v3/');
            // eslint-disable-next-line
            // @ts-ignore: getting from global window
            this.mutations.setStripeInstance(window.Stripe(deploy.WEB_PUBLIC_STRIPE_PUBLIC_KEY));
        }
    },

    async pay(stripeTokenId) {
        try {
            await submitPayment({ id: stripeTokenId });

            this.mutations.finishPayment();
        } catch (error) {
            await this.actions.showError(error);
        }
    },

    async sendConsent() {
        try {
            const session = this.state.session;

            // Handle case when user is not logged in and somehow sends consent
            if (!session) {
                await this.actions.showError('unauthorized-ido');
                return;
            }

            // Send consent for sharing data, when it is not GLS session (which doesn't support it)
            if (!isGlsSession(session)) {
                await sendConsent(session.rprId);
            }

            // Otherwise, mark that it has succeed
            this.mutations.setConsentStatus(true);
        } catch (error) {
            await this.actions.showError('submission-failed');
        }
    },

    async requestTransition() {
        try {
            const session = this.state.session;

            // Handle case when user is not logged in and somehow requests transition
            if (!session) {
                await this.actions.showError('unauthorized-ido');
                return;
            }

            // Get information about required transition
            const { status, transitionUri } = isGlsSession(session)
                ? await getGlsTransition()
                : await requestTransition(session.rprId);

            // Decide what to do with current interview
            if (status === 'transitionNotNeeded') {
                this.mutations.setStatus(Status.finished);
            } else if (status === 'transitionReady') {
                this.mutations.setStatus(Status.transition);
                redirect(transitionUri);
            }
        } catch (error) {
            await this.actions.showError(error);
        }
    },

    async submitGlsData(data) {
        const session = this.state.session;

        // Handle situation when there is regular Evident session used
        if (!session || !isGlsSession(session)) {
            await this.actions.showError('unexpected');
            return;
        }

        // Inform about submission of all attributes
        const attrTypes = this.state.questions.map((x) => x.attrType);
        this.mutations.setSubmissionStatus(createMapWithKeys(attrTypes, true));

        try {
            // Request submission
            await glsApiClient.submit(session.caseId, data);

            // Reload interview, to update data, session and completion status, based on back-end
            for (const question of this.state.questions) {
                this.mutations.completeQuestion(question.attrType);
            }
        } catch (error) {
            // Show error
            if (error?.status === 'invalid_business_address') {
                await this.actions.displaySnackbar({
                    success: false,
                    permanent: true,
                    message: 'The address you entered does not match a known address. Please enter the business address as it appears on Google Maps.',
                });
            } else {
                await this.actions.showError(error);
            }
        } finally {
            // Mark all attributes as submitted
            this.mutations.setSubmissionStatus(createMapWithKeys(attrTypes, false));
        }
    },

    async deleteGlsFieldWorker(email) {
        const session = this.state.session;

        // Handle situation when there is regular Evident session used
        if (!session || !isGlsSession(session)) {
            await this.actions.showError('unexpected');
            return;
        }

        const attributeType = GlsAttributeType.fieldWorkers in this.state.values
            ? GlsAttributeType.fieldWorkers
            : GlsAttributeType.businessOwnersAndFieldworkers;

        // Build next list of field workers
        const { list } = this.state.values[attributeType] || { list: [] };
        const nextList = list.filter((x: string) => x !== email);

        // Ensure that there was any change
        if (list.length === nextList.length) {
            return;
        }

        // Inform about field workers update in progress
        this.mutations.setSubmissionStatus({ [attributeType]: true });

        try {
            // Request fieldworker deletion
            await glsApiClient.deleteFieldWorker(session.caseId, email);

            // Update current value displayed
            await this.actions.changeValue({
                name: attributeType,
                value: { confirmed: true, list: nextList },
            });
        } catch (error) {
            await this.actions.showError(error);
        } finally {
            // Inform about end of submission
            this.mutations.setSubmissionStatus({ [attributeType]: false });
        }
    },

    async addGlsFieldWorker(email: string) {
        const session = this.state.session;

        // Handle situation when there is regular Evident session used
        if (!session || !isGlsSession(session)) {
            await this.actions.showError('unexpected');
            return;
        }

        if (emailAlreadyExists(GlsAttributeType, this.state.values, email)) {
            await this.actions.displaySnackbar({
                success: false,
                message: 'This e-mail address is already used.',
            });
            return;
        }

        const attributeType = GlsAttributeType.fieldWorkers in this.state.values
            ? GlsAttributeType.fieldWorkers
            : GlsAttributeType.businessOwnersAndFieldworkers;

        // Build next list of field workers
        const { list } = this.state.values[attributeType] || { list: [] };

        // Inform about field workers update in progress
        this.mutations.setSubmissionStatus({ [attributeType]: true });

        try {
            // Request fieldworker add
            await glsApiClient.addFieldWorker(session.caseId, email);

            // Update current value displayed
            await this.actions.changeValue({
                name: attributeType,
                value: { confirmed: true, list: [ ...list, email ] },
            });
        } catch (error) {
            if (errorContainsText(error)) {
                await this.actions.displaySnackbar({
                    success: false,
                    message: error.error || error,
                });
            } else {
                await this.actions.showError(error);
            }
        } finally {
            // Inform about end of submission
            this.mutations.setSubmissionStatus({ [attributeType]: false });
        }
    },

    async addGlsBusinessOwner(email: string) {
        const session = this.state.session;

        // Handle situation when there is regular Evident session used
        if (!session || !isGlsSession(session)) {
            await this.actions.showError('unexpected');
            return;
        }

        if (emailAlreadyExists(GlsAttributeType, this.state.values, email)) {
            await this.actions.displaySnackbar({
                success: false,
                message: 'This e-mail address is already used.',
            });
            return;
        }

        const attributeType = GlsAttributeType.businessOwners in this.state.values
            ? GlsAttributeType.businessOwners
            : GlsAttributeType.businessOwnersAndFieldworkers;

        // Build next list of business owners
        const { list } = this.state.values[attributeType] || { list: [] };

        // Inform about field workers update in progress
        this.mutations.setSubmissionStatus({ [attributeType]: true });

        try {
            // Request business owner add
            await glsApiClient.addBusinessOwner(session.caseId, email);

            // Update current value displayed
            await this.actions.changeValue({
                name: attributeType,
                value: { confirmed: true, list: [ ...list, email ] },
            });
        } catch (error) {
            if (errorContainsText(error)) {
                await this.actions.displaySnackbar({
                    success: false,
                    message: error.error || error,
                });
            } else {
                await this.actions.showError(error);
            }
        } finally {
            // Inform about end of submission
            this.mutations.setSubmissionStatus({ [attributeType]: false });
        }
    },

    async resendGlsSubmissionLink(emails) {
        const session = this.state.session;

        // Handle situation when there is regular Evident session used
        if (!session || !isGlsSession(session)) {
            await this.actions.showError('unexpected');
            return;
        }

        // Split e-mails
        const existingBusinessOwners = this.state.values[GlsAttributeType.businessOwners]?.list || [];
        const [ businessOwners, fieldWorkers ] = partition(emails, (email) => existingBusinessOwners.includes(email));

        // Inform about submission in progress
        this.mutations.startGlsResendLink(emails);

        try {
            // Call API
            const [
                { failed: failedBusinessOwners },
                { failed: failedFieldWorkers },
            ] = await Promise.all([
                businessOwners.length > 0
                    ? glsApiClient.resendBusinessOwnersLink(session.caseId, businessOwners)
                    : Promise.resolve({ failed: [] as string[] }),
                fieldWorkers.length > 0
                    ? glsApiClient.resendFieldWorkersLink(session.caseId, fieldWorkers)
                    : Promise.resolve({ failed: [] as string[] }),
            ]);

            // Update submission status
            const failed = failedBusinessOwners.concat(failedFieldWorkers);
            const succeed = emails.filter((email) => !failed.includes(email));
            if (failed.length > 0) {
                this.mutations.failGlsResendLink(failed);
            }
            if (succeed.length > 0) {
                this.mutations.finishGlsResendLink(succeed);
            }
        } catch (error) {
            // Mark as failed and throw error (when unknown)
            this.mutations.failGlsResendLink(emails);
            await this.actions.showError(error);
        }
    },

    async deleteGlsBusinessOwner(email) {
        const session = this.state.session;

        // Handle situation when there is regular Evident session used
        if (!session || !isGlsSession(session)) {
            await this.actions.showError('unexpected');
            return;
        }

        // Revoke primary business owner
        if (email === this.state.gls?.primaryBusinessOwner) {
            await this.actions.displaySnackbar({
                success: false,
                message: 'You can\'t delete primary business owner.',
            });
            return;
        }

        const attributeType = GlsAttributeType.businessOwners in this.state.values
            ? GlsAttributeType.businessOwners
            : GlsAttributeType.businessOwnersAndFieldworkers;

        // Build next list of field workers
        const { list } = this.state.values[attributeType] || { list: [] };
        const nextList = list.filter((x: string) => x !== email);

        // Ensure that there was any change
        if (list.length === nextList.length) {
            return;
        }

        // Inform about field workers update in progress
        this.mutations.setSubmissionStatus({ [attributeType]: true });

        try {
            // Request fieldworker deletion
            await glsApiClient.deleteBusinessOwner(session.caseId, email);

            // Update current value displayed
            await this.actions.changeValue({
                name: attributeType,
                value: { confirmed: true, list: nextList },
            });
        } catch (error) {
            await this.actions.showError(error);
        } finally {
            // Inform about end of submission
            this.mutations.setSubmissionStatus({ [attributeType]: false });
        }
    },

    async redirectLocally(location) {
        // FIXME: Change usage of actions object, to be created each time, and get a router as argument for factory
        // As the import of idoApplication will break all unit tests, it's required dynamically.
        // For the same reason, there is no unit test for redirection.
        // eslint-disable-next-line @typescript-eslint/no-var-requires
        const app = require('@/idoApplication').default;
        if (app.router) {
            await app.router.push(location);
        }
    },
}));

const actions = factories.instantiateActions();
export const { instantiateActions } = factories;
export default actions;
