export interface SelfieContents {
    heading: string;
    reason: string;
    delegationInstructions: string;
}

const vanillaSelfieContent: SelfieContents = {
    heading: 'Smile! We need you to take a selfie.',
    reason: (
        `In order to verify your identity, we need to compare a
        photo of you to the photograph on your identity document.`
    ),
    delegationInstructions: (
        `Please enter your phone number below. We'll send a
        text message containing instructions on how to complete your
        selfie image on your mobile device.`
    ),
};

const paymentCardSelfieContent: SelfieContents = {
    heading: 'We need your selfie with a payment card.',
    reason: (
        `In order to verify your identity, we need a photo of
        you holding your payment card. Please take a selfie with your
        face and the details of your card clearly visible.`
    ),
    delegationInstructions: (
        `Please enter your phone number below. We'll send a
        text message containing instructions on how to complete your
        selfie image on your mobile device.`
    ),
};

const selfieContents: Record<string, SelfieContents> = {
    vanillaSelfieContent,
    paymentCardSelfieContent,
};

export default selfieContents;
