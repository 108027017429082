import { defaultFeatureFlags } from '@/services/getSessionInterview';
import IdoState, { Status } from './interfaces/IdoState';
import { createStateFactory } from '@evidentid/vue-commons/store';

// TODO: Use some App interface, split to different modules
const createState = createStateFactory();

const factories = createState<IdoState>(() => ({
    status: Status.uninitialized,
    consentGiven: false,
    error: null,
    snackbar: {
        success: false,
        permanent: false,
        message: null,
    },
    requestStatus: null,
    gls: null,
    session: null,
    user: null,
    issuer: null,
    balance: null,
    questions: [],
    summary: null,
    submission: {},
    values: {},
    academicProviders: null,
    utilityProviders: null,
    criminalOffenses: null,
    delegationPhoneNumber: null,
    delegationsRequested: {},
    verificationsRequested: {},
    featureFlags: { ...defaultFeatureFlags },
    stripe: null,
    glsResendLinkStatus: {},
}));

export const { createMutationsFactories } = factories;

export default function buildInitialState(): IdoState {
    return factories.instantiateState();
}
