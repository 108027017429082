import { Location } from 'vue-router/types/router';
import redirect from '@evidentid/vue-commons/router/redirect';
import { glsErrorLinkUrl } from '@/config/gls';

export interface ErrorDefinition {
    html?: boolean;
    warning?: boolean;
    heading: string;
    message: string;
}

const errors: Record<string, ErrorDefinition> = {
    'direct-to-original-link': {
        heading: 'Please use your original link.',
        message: `This page is unavailable. Please return to your phone
                  or email and click on the link originally sent.`,
    },
    'local-storage-required': {
        heading: 'Local storage required.',
        message: `You need to enable third-party data in your browser settings
                  to use this application. If you are using private mode in Safari,
                  please return with private mode disabled.`,
    },
    'session-storage-required': {
        heading: 'Session storage required.',
        message: `You need to enable third-party data in your browser settings
                  to use this application. If you are using private mode in Safari,
                  please return with private mode disabled.`,
    },
    'page-not-found': {
        heading: 'That doesn\'t seem to be here.',
        message: 'The page you have requested was not found.',
    },
    'submission-failed': {
        heading: 'Submission failed',
        message: `We could not submit your response at this time.
                  Please try again in a few minutes.`,
    },
    'unauthorized-ido': {
        heading: 'Unauthorized',
        message: `Please make sure to click the link sent to you
                  in a notification to start your session.`,
    },
    'facetec-unsupported-browser': {
        heading: 'Please try another browser',
        message: `
            To use this web application you need to use another client.
            Please start Firefox >= 52, Google Chrome, Safari >= 12, or Opera >= 44 and visit this address again.
            If you are using an iPhone you must use Safari.
        `,
    },
    'facetec-unsupported-browser-ios': {
        heading: 'Please try again with Safari',
        message: `
            Unfortunately, this web application uses browser technology
            that is only compatible with Safari.
        `,
    },
    'facetec-unsupported-browser-old': {
        heading: 'You may need to update your browser',
        message: `
            To use this web application, you may need a newer version fo your browser.
            We recommend Google Chrome or Firefox (version 52 or higher).
        `,
    },
    'facetec-device-in-landscape-mode': {
        heading: 'Landscape mode is not allowed',
        message: `
            Please hold your device in portrait mode and refresh a page.
        `,
    },
    'facetec-device-locked-out': {
        heading: 'Limits exceeded',
        message: `
            Too many requests encountered, please try again in few minutes.
        `,
    },
    'cancelled-network-request': {
        warning: true,
        heading: 'Network connection issues',
        message:
            'The request has been canceled, either because of a page refresh or network issues.',
    },
    'network-outage': {
        heading: 'Network connection error',
        message: `There was a connection problem. These are usually
                  intermittent, so please wait a few minutes and try again.`,
    },
    'unauthorized': {
        heading: 'You don\'t have permission to use this application.',
        message: 'Please contact the account administrator for access.',
    },
    'cookies-needed': {
        heading: 'Cookies are disabled.',
        message:
            'You need to enable third-party cookies and data in your browser settings to use this application.',
    },
    'unexpected': {
        heading: 'Uh oh, something went wrong!',
        message: `We're currently working on this, and should have things
                  back to normal soon. Please try again later.`,
    },
    'request-timeout': {
        heading: 'This request link is no longer valid',
        message:
            'To continue with this verification, you will need a new link from {{rpName}}.',
    },
    'gls/not-found': {
        heading: 'That doesn\'t seem to be here.',
        message: 'The verification you have requested was not found.',
    },
};

const unknownRequestRoute = ({ name: 'unknown-request-error' });
export const actionableErrors: Record<string, Location | (() => any)> = {
    'gls/unauthorized': () => redirect(glsErrorLinkUrl),
    'gls/forbidden': () => redirect(glsErrorLinkUrl),
    'unknown-request': unknownRequestRoute,
    // @see {@link https://github.com/evidentid/evidentid-py/blob/b76c8f4477da48fc1d9bb94e2b641de0a7fa9378/evidentid/api/provider/simple/auth/exception.py}
    'NoCredentialsSpecified': unknownRequestRoute,
    'InvalidCredentialsSpecified': unknownRequestRoute,
    'TokenExpired': unknownRequestRoute,
    'TokenRedeemed': unknownRequestRoute,
    'TokenVerificationFailed': unknownRequestRoute,
    'TokenSubjectDomainMismatch': unknownRequestRoute,
    'TokenInvalidDomainMismatch': unknownRequestRoute,
};

export default errors;
