import type { FaceTecSDK as _FaceTecSDK } from './core-sdk/FaceTecSDK.js/FaceTecSDK';
export type { FaceTecCustomization } from './core-sdk/FaceTecSDK.js/FaceTecCustomization';
export type { FaceTecSession } from './core-sdk/FaceTecSDK.js/FaceTecSession';
export type {
    FaceTecFaceScanProcessor,
    FaceTecFaceScanResultCallback,
    FaceTecSessionResult,
} from './core-sdk/FaceTecSDK.js/FaceTecPublicApi';

// Extract some basic type definitions from FaceTec
export type FaceTecSDK = typeof _FaceTecSDK;

/**
 * Copied from FaceTec SDK, so we don't have to include full SDK in the bundle.
 * @see {@link ./FaceTecSDK.js/FaceTecPublicApi.d.ts}
 */
export enum FaceTecSDKStatus {
    NeverInitialized = 0,
    Initialized = 1,
    NetworkIssues = 2,
    InvalidDeviceKeyIdentifier = 3,
    VersionDeprecated = 4,
    DeviceNotSupported = 5,
    DeviceInLandscapeMode = 6,
    DeviceLockedOut = 7,
    KeyExpiredOrInvalid = 8,
    IFrameNotAllowedWithoutPermission = 9,
    StillLoadingResources = 10,
    ResourcesCouldNotBeLoadedOnLastInit = 11
}

/**
 * Copied from FaceTec SDK, so we don't have to include full SDK in the bundle.
 * @see {@link ./FaceTecSDK.js/FaceTecPublicApi.d.ts}
 */
export enum FaceTecSessionStatus {
    SessionCompletedSuccessfully = 0,
    MissingGuidanceImages = 1,
    Timeout = 2,
    ContextSwitch = 3,
    ProgrammaticallyCancelled = 4,
    OrientationChangeDuringSession = 5,
    LandscapeModeNotAllowed = 6,
    UserCancelled = 7,
    UserCancelledFromNewUserGuidance = 8,
    UserCancelledFromRetryGuidance = 9,
    UserCancelledWhenAttemptingToGetCameraPermissions = 10,
    LockedOut = 11,
    CameraNotEnabled = 12,
    NonProductionModeDeviceKeyIdentifierInvalid = 13,
    DocumentNotReady = 14,
    SessionInProgress = 15,
    CameraNotRunning = 16,
    InitializationNotCompleted = 17,
    UnknownInternalError = 18,
    UserCancelledViaClickableReadyScreenSubtext = 19,
    NotAllowedUseIframeConstructor = 20,
    NotAllowedUseNonIframeConstructor = 21,
    IFrameNotAllowedWithoutPermission = 22,
    StillLoadingResources = 23,
    ResourcesCouldNotBeLoadedOnLastInit = 24,
    UserCancelledFullScreenMode = 25,
}

/**
 * Copied from FaceTec SDK, so we don't have to include full SDK in the bundle.
 * @see {@link ./FaceTecSDK.js/FaceTecPublicApi.d.ts}
 */
export enum FaceTecVocalGuidanceMode {
    MINIMAL_VOCAL_GUIDANCE = 0,
    FULL_VOCAL_GUIDANCE = 1,
    NO_VOCAL_GUIDANCE = 2
}

// Definitions for our implementation
export interface LivenessCheckResultSuccessful {
    status: FaceTecSessionStatus.SessionCompletedSuccessfully;
    sessionId: string;
    userAgent: string;
    faceScan: any;
    auditTrailImage: any;
    lowQualityAuditTrailImage: any;
}

export interface LivenessCheckResultFailed {
    status: Exclude<FaceTecSessionStatus, FaceTecSessionStatus.SessionCompletedSuccessfully>;
    userAgent: null;
    sessionId: null;
    faceScan: null;
    auditTrailImage: null;
    lowQualityAuditTrailImage: null;
}

export type LivenessCheckResult = LivenessCheckResultSuccessful | LivenessCheckResultFailed;

export interface ZoomInitializationOptions {
    publicDirectory: string;
    deviceLicenseKeyIdentifier: string;
    publicEncryptionKey: string;
    productionKey?: string | null;
    zoomServerSessionTokenUrl: string;
    zoomServerSessionTokenIncludeCredentials?: boolean;
    zoomServerVerifyUrl?: string | null;
    zoomServerVerifyIncludeCredentials?: boolean;
    loadSdk?: () => Promise<FaceTecSDK>;
}

export enum LivenessVerificationStatus {
    success = 'PASSED',
    error = 'UNDETERMINED',
    internalError = 'PROVIDER_ERROR',
}

export interface VerifyLivenessOptions {
    sessionId: string;
    faceScan: string;
    auditTrail: string;
}

export type VerifyLiveness = (options: VerifyLivenessOptions) => Promise<LivenessVerificationStatus>;
