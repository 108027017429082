import formatMessage from './formatMessage';

const _cache = {};

/*
Opinionated error space that ignores 'instanceof'-centric
checks in favor of canonical string reason codes, opt-in message
formatting and context prescription via overridable factories.

See errors.test.js for example usage.
*/
export function exception({ reason, message, extra, config } = {}) {
    const rsn = reason || 'unexpected';
    const msg = message || rsn;
    const cfg = config || {};
    const xtra = extra || {};

    const e = new Error(cfg.format ? formatMessage(msg, xtra) : msg);

    e.reason = rsn;
    e.extra = cfg.format
        ? Object.entries(xtra).reduce((p, [ k, v ]) => {
            p[k] = typeof v === 'string' ? formatMessage(v, xtra) : v;
            return p;
        }, {})
        : xtra;

    return e;
}

export function definition(base, baseopts = {}) {
    if (typeof baseopts.reason !== 'string' || !baseopts.reason) {
        throw exception({
            reason: 'errors/no-reason',
            message: 'A string reason must be specified to define an error',
        });
    }

    if (_cache[baseopts.reason]) {
        throw exception({
            reason: 'errors/reason-conflict',
            message: `Reason code ${baseopts.reason} is already taken.`,
        });
    }

    _cache[baseopts.reason] = (overrides = {}) =>
        (base || exception)(Object.assign(
            {}, baseopts, overrides));

    // So that you don't need to build an error to reference its code.
    _cache[baseopts.reason].reason = baseopts.reason;

    return _cache[baseopts.reason];
}

export function lookup(reason) {
    return _cache[reason];
}

export const Unexpected = definition(exception, {
    reason: 'unexpected',
    extra: {
        displaySummary: 'Uh oh, something went wrong!',
        displayMessage: `We're currently working on this, and should have things
            back to normal soon. Please try again later.`,
    },
});

export const AuthorizationError = definition(exception, {
    reason: 'unauthorized',
    extra: {
        displaySummary: 'You don\'t have permission to use this application.',
        displayMessage: 'Please contact the account administrator for access.',
    },
});
