import { getIdentity, getIdoProfile, getVerificationRequestDetails } from '@evidentid/ido-lib/idoWebClient';
import { curate } from '@evidentid/ido-lib/curation';
import { aggregate } from '@evidentid/ido-lib/aggregation';
import getIdoRequestStatus, { IdoRequestStatus, RelyingPartyRequestStatusType } from '@evidentid/ido-lib/getIdoRequestStatus';
import { RegularSessionData } from '@/interfaces/SessionData';
import UserData from '@/interfaces/UserData';
import Identity, { ConsentHtml } from '@evidentid/ido-lib/interfaces/Identity';
import Question from '@evidentid/ido-lib/interfaces/Question';
import getFieldType from '@/fields/getFieldType';
import Balance from '@/interfaces/Balance';
import { RelyingPartyRequestFeatureFlags } from '@evidentid/ido-lib/interfaces/AttributeRelyingPartyRequest';
import { ValuesMap } from '@/store/interfaces/IdoState';
import { defaultPrivacyPolicy } from '@/config/issuer';
import { GlsData } from '@/services/getGlsSessionInterview';

export interface SessionInterviewDetails {
    user: UserData;
    issuer: Identity;
    gls?: GlsData;
    questions: Question[];
    balance: Balance | null;
    summary: string | null;
    status: IdoRequestStatus;
    featureFlags: RelyingPartyRequestFeatureFlags;
    values?: ValuesMap;
}

export const defaultConsentHtmls: Record<string, ConsentHtml> = {
    'consent.biometric.authorization': {
        data: `
            <p>
                When you submit your selfie,
                we use it to create a face map to verify your identity and confirm that you are a real person.
                We share it only with third parties that you have authorized.
                You agree we can use your face map and related data for this purpose
                and as described in detail
                in our <a href="https://www.evidentid.com/privacy-policy/" target="_blank">Privacy Policy.</a>
            </p>
        `,
        mime_type: 'text/html',
        metadata: {},
    },
};

export const defaultFeatureFlags: RelyingPartyRequestFeatureFlags = {
    idverify_document_capture_retry_enabled: true,
    idverify_document_upload_from_mobile_enabled: false,
    idverify_document_upload_from_desktop_enabled: false,
    idverify_user_media_overlay_enabled: false,
    rpr_constant_polling_enabled: true,
};

// TODO(PRODUCT-10068): integrate real feature flags
const featureFlagsOverrides: Record<string, Partial<RelyingPartyRequestFeatureFlags>> = {
    rp1: {
        idverify_document_upload_from_mobile_enabled: true,
        idverify_document_upload_from_desktop_enabled: true,
    },
    okta: {
        idverify_document_upload_from_mobile_enabled: true,
        idverify_document_upload_from_desktop_enabled: true,
    },
    owens: {
        idverify_document_upload_from_mobile_enabled: true,
        idverify_document_upload_from_desktop_enabled: true,
    },
    mandately: {
        idverify_document_upload_from_mobile_enabled: true,
        idverify_document_upload_from_desktop_enabled: true,
    },
    uspto: {
        idverify_document_upload_from_mobile_enabled: true,
        idverify_document_upload_from_desktop_enabled: true,
    },
    evidentlabs: {
        idverify_document_upload_from_mobile_enabled: true,
        idverify_document_upload_from_desktop_enabled: true,
    },
    standardoffice: {
        idverify_document_upload_from_mobile_enabled: true,
        idverify_document_upload_from_desktop_enabled: true,
    },
    taskcheetah: {
        idverify_document_upload_from_mobile_enabled: true,
        idverify_document_upload_from_desktop_enabled: true,
    },
    caminofinancial: {
        idverify_document_upload_from_mobile_enabled: true,
        idverify_document_upload_from_desktop_enabled: true,
    },
};

const IMMEDIATE_FINISH_STATUSES = [
    RelyingPartyRequestStatusType.complete,
    RelyingPartyRequestStatusType.processing,
];

export default async function getSessionInterview(
    sessionData: RegularSessionData,
    existingUser?: UserData | null,
    existingIssuer?: Identity | null,
    blockImmediateFinish?: boolean,
): Promise<SessionInterviewDetails> {
    // Load or reuse required data
    const user = existingUser ? existingUser : await getIdoProfile();
    const rpRequest = await getVerificationRequestDetails(sessionData.rprId);
    const originalIssuer = existingIssuer || (await getIdentity(rpRequest.relyingparty_id));
    const issuer = { ...originalIssuer };
    const status = getIdoRequestStatus(rpRequest);
    const [ , relyingPartyId ] = issuer.id.match(/CN=([^/]+)/) || [];

    // Patch issuer consents, to have the hardcoded default values too
    issuer.consentHtml = { ...defaultConsentHtmls, ...issuer.consentHtml };
    issuer.privacyPolicy = { ...defaultPrivacyPolicy, ...issuer.privacyPolicy };

    // When user has been delegated to external flow,
    // we would like to simulate the final transition in current session too.
    if (blockImmediateFinish && IMMEDIATE_FINISH_STATUSES.includes(status.type)) {
        status.type = RelyingPartyRequestStatusType.new;
    }

    // Build information about current RP request
    const requirements = curate(aggregate(rpRequest, issuer));
    const questions = Object.values(requirements.input).filter(Boolean).map((question) => ({
        ...question,
        type: getFieldType(question),
    })) as Question[];

    return {
        user,
        balance: requirements.balance,
        issuer,
        questions,
        status,
        featureFlags: {
            ...defaultFeatureFlags,
            ...featureFlagsOverrides[relyingPartyId], // TODO(PRODUCT-10068): integrate real feature flags
            ...rpRequest.feature_flags,
        },
        summary: rpRequest.summary || null,
    };
}
