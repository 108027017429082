import logger from '@evidentid/universal-framework/logger';

export function buildFileFromBlob(blob: Blob, fileName: string, type?: string): File {
    try {
        return new File([ blob ], fileName, { type: type || blob.type });
    } catch (e) {
        // Hack for Microsoft Edge: doesn't support File constructor
        const pseudoFile = new Blob([ blob ], { type: type || blob.type });
        // @ts-ignore: name is not a real Blob property
        pseudoFile.name = fileName;
        return pseudoFile as File;
    }
}

export function downloadFile(file: Blob, filename: string): void;
export function downloadFile(file: File, filename?: string): void;
export function downloadFile(file: File | Blob, filename?: string): void {
    const name = filename || ('name' in file ? file.name : undefined);
    if (name == null) {
        throw new Error('You need to pass the File or attach the file name.');
    }

    const downloadableFile = buildFileFromBlob(file, name, 'application/octet-stream');
    const url = (window.URL || window.webkitURL).createObjectURL(downloadableFile);
    const a = document.createElement('a');

    a.style.display = 'none';
    a.href = url;
    a.download = name;

    document.body.appendChild(a);
    a.click();

    URL.revokeObjectURL(url);
    a.remove();
}

export function downloadFileUsingBinString(content: string, filename: string): void {
    return downloadFile(new Blob([ content ]), filename);
}

export function downloadFileUsingBase64String(base64Str: string, filename: string): void {
    const data = new Uint8Array([ ...atob(base64Str) ].map((char) => char.charCodeAt(0)));
    return downloadFile(new Blob([ data ]), filename);
}

function _readFile(blob: Blob, operation: 'readAsDataURL'): Promise<string>;
function _readFile(blob: Blob, operation: 'readAsArrayBuffer'): Promise<ArrayBuffer>;
function _readFile(blob: Blob, operation: 'readAsDataURL' | 'readAsArrayBuffer'): Promise<string | ArrayBuffer> {
    return new Promise((resolve, reject) => {
        const fileReader = new FileReader();

        fileReader.addEventListener('load', () => {
            if (!fileReader.result) {
                return reject(fileReader.error || new Error('Something went wrong while reading File - empty File received'));
            }
            resolve(fileReader.result as any);
        }, { capture: false, once: true });

        fileReader.addEventListener('error', (e) => {
            logger.error(`${operation}: ${fileReader.error?.message} [type: ${e.type}, phase: ${e.eventPhase}, full: ${e.loaded === e.total ? 'yes' : 'no'}]`);
            reject(e);
        }, { capture: false, once: true });

        fileReader[operation](blob);
    });
}

export const readFile = {
    asDataURL: (blob: Blob) => _readFile(blob, 'readAsDataURL'),
    asArrayBuffer: (blob: Blob) => _readFile(blob, 'readAsArrayBuffer'),
};

export async function cloneFile(file: File): Promise<File> {
    const arrayBuffer = await readFile.asArrayBuffer(file);
    try {
        return new File([ arrayBuffer ], file.name, { type: file.type });
    } catch (e) {
        // Hack for Microsoft Edge: doesn't support File constructor
        const pseudoFile = new Blob([ arrayBuffer ], { type: file.type });
        // @ts-ignore: name is not a real Blob property
        pseudoFile.name = file.name;
        return pseudoFile as File;
    }
}
