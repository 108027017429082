import Question from '@evidentid/ido-lib/interfaces/Question';

export enum CoverageQuestionGroup {
    acord25 = 'acord25',
    certificate = 'certificate',
    insurer = 'insurer',
    hidden = 'hidden',
    limit = 'limit',
    typeOfInsurance = 'typeOfInsurance',
    fallback = 'fallback',
}

export interface CoverageQuestions {
    acord25: Record<string, Question>;
    certificate: Record<string, Question>;
    insurer: Record<string, Question>;
    hidden: Record<string, Question>;
    limit: Question[];
    typeOfInsurance: Question[];
    fallback: Question[];
}

export interface CoverageType {
    name: string;
    questions: CoverageQuestions;
}
