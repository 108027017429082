import mapValues from 'lodash/mapValues';
import delay from '@evidentid/universal-framework/delay';
import {
    FaceTecCustomization,
    FaceTecSDK,
    FaceTecSDKStatus,
    FaceTecSessionStatus,
    LivenessCheckResult,
    LivenessVerificationStatus,
    ZoomInitializationOptions,
} from '../types';
import { loadFaceTecSDK } from './loadFaceTecSDK';
import { buildFailedLivenessCheckResult } from './buildFailedLivenessCheckResult';
import { LivenessCheckProcessor } from './LivenessCheckProcessor';
import { AbortError } from './AbortError';

interface FinalZoomInitializationOptions extends ZoomInitializationOptions {
    zoomServerSessionTokenIncludeCredentials: boolean;
    zoomServerVerifyIncludeCredentials: boolean;
    zoomServerVerifyUrl: string | null;
}

interface CheckLivenessOptions {
    signal?: AbortSignal;
    headers?: Record<string, string>;
    maxTries?: number;
    onRetry?: (triesCount: number) => any;
}

interface FaceTecRequestOptions {
    url: string;
    method?: 'GET' | 'POST';
    includeCredentials?: boolean;
    sessionId?: string;
    headers?: Record<string, string>;
    body?: any;
}

interface VerificationStatusOptions {
    sessionId: string;
    faceScan: string; // Base64
    auditTrail: string; // Base64
    headers?: Record<string, string>;
}

type FaceTecCustomizationPartial = Partial<{
    [K in keyof FaceTecCustomization]: Partial<FaceTecCustomization[K]>;
}>;

class FaceTecWrapper {
    private readonly sdk: Readonly<FaceTecSDK>;
    private readonly options: Readonly<FinalZoomInitializationOptions>;
    private readonly customization: FaceTecCustomization;

    private constructor(sdk: FaceTecSDK, options: ZoomInitializationOptions) {
        this.sdk = sdk;
        this.customization = new sdk.FaceTecCustomization();
        this.sdk.setCustomization(this.customization);
        this.options = {
            ...options,
            zoomServerSessionTokenIncludeCredentials: Boolean(options.zoomServerSessionTokenIncludeCredentials),
            zoomServerVerifyIncludeCredentials: Boolean(options.zoomServerVerifyIncludeCredentials),
            zoomServerVerifyUrl: options.zoomServerVerifyUrl || null,
        };
    }

    public get preflightVerificationEnabled(): boolean {
        return Boolean(this.options.zoomServerVerifyUrl);
    }

    public get status(): FaceTecSDKStatus {
        return this.sdk.getStatus();
    }

    public get statusDescription(): string {
        return this.sdk.getFriendlyDescriptionForFaceTecSDKStatus(this.status);
    }

    public get ready(): boolean {
        return this.status === FaceTecSDKStatus.Initialized;
    }

    private format<T>(value: T, includeLevelDown = false): T {
        if (typeof value === 'string') {
            // @ts-ignore: type definition narrowing is not working fine there
            return value.replace(/{{([^}]+)}}/g, (_, name) => this.options[name]);
        } else if (includeLevelDown && value && typeof value === 'object') {
            // @ts-ignore: type definition narrowing is not working fine there
            return mapValues(value, this.format.bind(this));
        } else {
            return value;
        }
    }

    private async request(options: FaceTecRequestOptions): Promise<any> {
        const headers: Record<string, string> = {
            'cache-control': 'no-cache',
            'accept': 'application/json',
            'content-type': 'application/json',
            'X-Device-Key': this.options.deviceLicenseKeyIdentifier,
            'X-User-Agent': this.sdk.createFaceTecAPIUserAgentString(options.sessionId || ''),
            ...options.headers,
        };

        return new Promise((resolve, reject) => {
            const method = options.method || 'GET';
            const xhr = new XMLHttpRequest();
            xhr.withCredentials = Boolean(options.includeCredentials);
            xhr.open(method, options.url);

            for (const name of Object.keys(headers)) {
                xhr.setRequestHeader(name, headers[name]);
            }

            xhr.addEventListener('load', () => {
                try {
                    const json = JSON.parse(xhr.responseText) || {};
                    resolve('result' in json ? json.result : json);
                } catch (error) {
                    reject(error);
                }
            });

            xhr.addEventListener('error', () => {
                if (xhr.status === 0) {
                    reject(new AbortError('FaceTec HTTP error: request aborted or page refreshed'));
                } else {
                    reject(new Error('FaceTec HTTP Error'));
                }
            });

            if (method === 'GET' || options.body === undefined) {
                xhr.send();
            } else {
                xhr.send(JSON.stringify(options.body));
            }
        });
    }

    public async getVerificationStatus(options: VerificationStatusOptions): Promise<LivenessVerificationStatus> {
        if (!this.preflightVerificationEnabled) {
            throw new Error('The verification mechanism is not configured properly.');
        }

        try {
            const { livenessStatus } = await this.request({
                method: 'POST',
                url: this.options.zoomServerVerifyUrl!,
                includeCredentials: this.options.zoomServerVerifyIncludeCredentials,
                sessionId: options.sessionId,
                headers: options.headers,
                body: { facescan: options.faceScan, selfie_image: options.auditTrail },
            });

            if (typeof livenessStatus !== 'string') {
                throw new Error('The FaceTec liveness status has not been found in the response.');
            }

            return livenessStatus as LivenessVerificationStatus;
        } catch (error) {
            if (error instanceof AbortError) {
                console.warn('FaceTec liveness status verification aborted or had connection issues');
            } else {
                console.error('FaceTec liveness status verification error', error);
            }
            throw error;
        }
    }

    public async getSessionToken(additionalHeaders: Record<string, string> = {}): Promise<string> {
        try {
            const { sessionToken } = await this.request({
                url: this.options.zoomServerSessionTokenUrl,
                includeCredentials: this.options.zoomServerSessionTokenIncludeCredentials,
                headers: additionalHeaders,
            });

            if (typeof sessionToken !== 'string') {
                throw new Error('The FaceTec session token has not been found in the response.');
            }

            return sessionToken;
        } catch (error) {
            if (error instanceof AbortError) {
                console.warn('FaceTec session token retrieval aborted or had connection issues');
            } else {
                console.error('FaceTec session token retrieval error', error);
            }
            throw error;
        }
    }

    public async checkLiveness(options: CheckLivenessOptions): Promise<LivenessCheckResult> {
        const { signal, headers, maxTries, onRetry } = options;
        let sessionToken: string;
        try {
            sessionToken = await this.getSessionToken(headers);
        } catch (error) {
            const reason = error instanceof AbortError
                ? FaceTecSessionStatus.ProgrammaticallyCancelled
                : FaceTecSessionStatus.UnknownInternalError;
            return buildFailedLivenessCheckResult(reason);
        }

        if (signal?.aborted) {
            return buildFailedLivenessCheckResult(FaceTecSessionStatus.ProgrammaticallyCancelled);
        }

        return new Promise((resolve) => {
            const verify = this.preflightVerificationEnabled ? this.getVerificationStatus.bind(this) : null;
            const processor = new LivenessCheckProcessor(this.sdk, sessionToken, resolve, {
                verify,
                maxTries,
                onRetry,
            });

            signal?.addEventListener('abort', () => processor.cancel());
        });
    }

    public customize(options: FaceTecCustomizationPartial): void {
        // Update settings
        for (const key of Object.keys(options) as (keyof FaceTecCustomizationPartial)[]) {
            if (options[key] == null) {
                // Omit
            } else if (typeof options[key] === 'object') {
                Object.assign(this.customization[key], this.format(options[key], true));
            } else {
                Object.assign(this.customization, { [key]: this.format(options[key]) });
            }
        }

        // Apply customization
        this.sdk.setCustomization(this.customization);
    }

    public localize(localizationJson: Record<string, string>): void {
        this.sdk.configureLocalization(localizationJson);
    }

    public setOverrideResultScreenSuccessMessage(message: string): void {
        this.sdk.FaceTecCustomization.setOverrideResultScreenSuccessMessage(message);
    }

    public initialize(): Promise<FaceTecSDKStatus> {
        return new Promise((resolve) => {
            const waitForInitializationStatus = async () => {
                while (this.status === 0) {
                    await delay(20);
                }
                resolve(this.status);
            };
            if (this.options.productionKey) {
                this.sdk.initializeInProductionMode(
                    this.options.productionKey,
                    this.options.deviceLicenseKeyIdentifier,
                    this.options.publicEncryptionKey,
                    waitForInitializationStatus,
                );
            } else {
                this.sdk.initializeInDevelopmentMode(
                    this.options.deviceLicenseKeyIdentifier,
                    this.options.publicEncryptionKey,
                    waitForInitializationStatus,
                );
            }
        });
    }

    public static async create(options: Readonly<ZoomInitializationOptions>): Promise<FaceTecWrapper> {
        // Clean the public directory
        const publicDirectory = options.publicDirectory.replace(/\/+$/, '');

        // Set-up SDK loader
        const load = options.loadSdk || loadFaceTecSDK;

        // Load FaceTec SDK
        const sdk = await load(publicDirectory).catch((error) => {
            if (error?.message === 'The request has been aborted or had connection issues') {
                throw new AbortError('The FaceTecSDK.js request has been aborted or had connection issues.');
            } else {
                console.error(error);
                throw error;
            }
        });

        // Ensure the SDK is there
        if (!sdk) {
            throw new Error('FaceTecSDK is not available after including its JS scripts.');
        }

        // Set-up paths
        sdk.setResourceDirectory(`${publicDirectory}/FaceTecSDK.js/resources`);
        sdk.setImagesDirectory(`${publicDirectory}/FaceTec_images`);

        // Instantiate new wrapper
        return new FaceTecWrapper(sdk, { ...options, publicDirectory });
    }
}

export default FaceTecWrapper;
