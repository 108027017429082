import { Subscriber } from '@evidentid/subscriber';
import JsonForm, {
    IS_VALID_FNC_PROP_KEY_NAME,
    JsonFormArray,
    JsonFormObject,
    JsonFormProperty,
    JsonFormType,
    JsonFormValidator,
} from './interfaces/JsonForm';
import cloneDeep from 'lodash/cloneDeep';
import isNil from 'lodash/isNil';

function randomString(): string {
    return `_${Math.floor(Math.random() * 1e13).toString(36)}`;
}

type Writable<T> = { -readonly [P in keyof T]: T[P] };

const INTERNAL = Symbol();
const VALUE = Symbol();
const PROXY_VALUE = Symbol();
const FORM_PROPERTIES = Symbol();
const LISTENER = Symbol();
const SUBSCRIBER = Symbol();
const SUBSCRIBER_VALUE = Symbol();
const SUBSCRIBER_DISABLED = Symbol();
const SUBSCRIBER_TOUCHED = Symbol();
const DISABLED = Symbol();
const TOUCHED = Symbol();
const AFTER_INTERNAL_UPDATE = Symbol();
const AFTER_INTERNAL_DISABLE_UPDATE = Symbol();
const AFTER_INTERNAL_TOUCHED_UPDATE = Symbol();

export type FormControllerValidationFnc<T=any, V=any> = (formControllerValue: T, value?: V) => boolean;
type FormControllerObject<T = any> = Record<string, FormControllerValidationFnc<T>>;

export interface FormControllerObjectFnc<T = any> {
    [key: string]: FormControllerObject<T> | FormControllerValidationFnc<T> | FormControllerObjectFnc<T>;
}

type FormControllerValidation = FormControllerValidationFnc | FormControllerObjectFnc;

interface PointerOptions<T> {
    form: JsonForm;
    id: string;
    value?: T | undefined;
    required: boolean;
    disabled: boolean;
    touched: boolean;
    deletable: boolean;
    depth: number;
    onChange: (value: T) => void;
    validation?: FormControllerValidation;
    getFormControllerValue: () => any;
    valuePath: string[];
}

// eslint-disable-next-line no-use-before-define
export type Pointer = ObjectPointer | ArrayPointer | PrimitivePointer;

function createPointer(options: PointerOptions<any>): Pointer {
    if (options.form.type === JsonFormType.object) {
        // eslint-disable-next-line no-use-before-define
        return new ObjectPointer(options);
    } else if (options.form.type === JsonFormType.array) {
        // eslint-disable-next-line no-use-before-define
        return new ArrayPointer(options);
    }
    // eslint-disable-next-line no-use-before-define
    return new PrimitivePointer(options);
}

function getPropertyDescriptor(object: any, key: string): PropertyDescriptor | null {
    let current = object;
    while (current && typeof current === 'object' && current !== Object.prototype) {
        const descriptor = Object.getOwnPropertyDescriptor(current, key);
        if (descriptor) {
            return descriptor;
        }
        current = Object.getPrototypeOf(current);
    }
    return null;
}

function exposeDescriptor(object: any, key: string): void {
    const descriptor = getPropertyDescriptor(object, key);
    if (descriptor && (descriptor.configurable || !Object.getOwnPropertyDescriptor(object, key))) {
        Object.defineProperty(object, key, { ...descriptor, enumerable: true });
    }
}

/**
 * Validate through all the properties of a controller against a validation
 */
function validateWithValidation(formValue: any, value: any, validation?: FormControllerValidation): boolean {
    if (!validation) {
        return true;
    }

    const valueIsArray = Array.isArray(value);
    const valueIsObject = typeof value === 'object';

    if (typeof validation === 'function') {
        if (valueIsArray) {
            return value.every((item) => validation(formValue, item));
        }

        return validation(formValue, value);
    }

    if (valueIsObject && !valueIsArray) {
        return Object.entries(value).every(([ entryKey, entryValue ]) => {
            const entryValidation = validation[entryKey];

            if (!entryValidation) {
                return true;
            }

            return validateWithValidation(formValue, entryValue, entryValidation);
        });
    }

    if (valueIsArray) {
        return value.every((item) => validateWithValidation(formValue, item, validation));
    }

    if (isNil(value)) {
        return true;
    }

    throw new Error('Validation is not the function when a value is not an object and not an array.');
}

/**
 * Get a new form value with the currently updated value under the valuePath
 */
function getUpdatedFormValue(valuePath: string[], formValue: any, targetValue: any) {
    const updatedFormValue = cloneDeep(formValue);
    if (valuePath.length === 0) {
        return {
            updatedFormValue,
            ...targetValue,
        };
    }

    const currentPoint = updatedFormValue[valuePath[0]];
    let i = 1;
    while (i < valuePath.length) {
        currentPoint[valuePath[i]] = {};
        i += 1;
    }
    currentPoint[valuePath[i - 1]] = targetValue;
    return updatedFormValue;
}

export interface PointerState<T> {
    value: T;
    disabled: boolean;
    touched: boolean;
}

abstract class AbstractPointer<T> {
    public readonly form: JsonForm;
    public readonly id: string;
    public readonly required: boolean;
    public readonly deletable: boolean;
    public readonly depth: number;
    public readonly bareValid!: boolean;
    public readonly valid!: boolean;
    public readonly empty!: boolean;
    public readonly disabled!: boolean;
    public readonly validation?: FormControllerValidation;
    public readonly valuePath!: string[];
    public readonly getFormControllerValue!: () => any;

    private [DISABLED]: boolean;
    private [TOUCHED]: boolean;
    private [VALUE]!: T;
    private [LISTENER]: (value: T) => void;
    private [INTERNAL]: Writable<this> = this;
    private [SUBSCRIBER]: Subscriber<PointerState<T>> = new Subscriber();
    private [SUBSCRIBER_VALUE]: Subscriber<T> = new Subscriber();
    private [SUBSCRIBER_DISABLED]: Subscriber<boolean> = new Subscriber();
    private [SUBSCRIBER_TOUCHED]: Subscriber<boolean> = new Subscriber();

    public constructor(options: PointerOptions<T>) {
        this.getFormControllerValue = options.getFormControllerValue;
        this.form = options.form;
        this.id = `${options.id}_${options.form.key}`;
        this.required = options.required;
        this.deletable = options.deletable;
        this.depth = options.depth;
        this.validation = options.validation;
        this.valuePath = options.valuePath;
        this[LISTENER] = options.onChange;
        this[DISABLED] = options.disabled || Boolean(options.form.schema?.readOnly);
        this[TOUCHED] = options.touched;
        this.computeValue(options.value!);
        this.computeDisabled();

        // HACK: Mark value as enumerable property
        exposeDescriptor(this, 'value');
        exposeDescriptor(this, 'touched');
        exposeDescriptor(this, 'disabled');
    }

    private computeValue(value: T): void {
        const empty = this.form.isEmpty(value, true);
        const bareValid = this.form.isValid(value, true);
        const valid = bareValid || (!this.required && empty);
        if (value !== this[VALUE] || empty !== this.empty || bareValid !== this.bareValid || valid !== this.valid) {
            this[VALUE] = value;
            this[INTERNAL].empty = empty;
            this[INTERNAL].bareValid = bareValid;
            this[INTERNAL].valid = valid;
            this[AFTER_INTERNAL_UPDATE]();
        }
    }

    private computeDisabled(): void {
        const disabled = this[DISABLED] || Boolean(this.form.schema?.readOnly);
        if (this.disabled !== disabled) {
            this[INTERNAL].disabled = disabled;
            this[AFTER_INTERNAL_DISABLE_UPDATE]();
        }
    }

    private computeTouched(): void {
        this[AFTER_INTERNAL_TOUCHED_UPDATE]();
    }

    protected [AFTER_INTERNAL_TOUCHED_UPDATE](): void {
        // Do nothing by default
    }

    protected [AFTER_INTERNAL_DISABLE_UPDATE](): void {
        // Do nothing by default
    }

    protected [AFTER_INTERNAL_UPDATE](): void {
        // Do nothing by default
    }

    public internalUpdate(value: T): void {
        if (this[VALUE] === value) {
            return;
        }
        this.computeValue(value);

        // Emit information about update
        this[SUBSCRIBER_VALUE].emit(this[VALUE]);
        this[SUBSCRIBER].emit({
            value: this[VALUE],
            disabled: this.disabled,
            touched: this.touched,
        });
    }

    public internalSetParentDisabled(disabled: boolean): void {
        if (this[DISABLED] === disabled) {
            return;
        }
        this[DISABLED] = disabled;
        this.computeDisabled();

        // Emit information about update
        this[SUBSCRIBER_DISABLED].emit(this.disabled);
        this[SUBSCRIBER].emit({
            value: this[VALUE],
            disabled: this.disabled,
            touched: this.touched,
        });
    }

    public internalSetParentTouched(touched: boolean): void {
        const changed = touched !== this[TOUCHED];
        this[TOUCHED] = touched;
        this.computeTouched();

        // Emit information about update
        if (changed) {
            this[SUBSCRIBER_TOUCHED].emit(this.touched);
            this[SUBSCRIBER].emit({
                value: this[VALUE],
                disabled: this.disabled,
                touched: this.touched,
            });
        }
    }

    public get value(): T {
        return this[VALUE];
    }

    public set value(value: T) {
        this[LISTENER](value);
    }

    public get touched(): boolean {
        return this[TOUCHED];
    }

    public touch(): void {
        this.internalSetParentTouched(true);
    }

    public onChange(listener: (data: PointerState<T>) => void): () => void {
        return this[SUBSCRIBER].listen(listener);
    }

    public onValueChange(listener: (value: T) => void): () => void {
        return this[SUBSCRIBER_VALUE].listen(listener);
    }

    public onDisabledChange(listener: (disabled: boolean) => void): () => void {
        return this[SUBSCRIBER_DISABLED].listen(listener);
    }

    public onTouchedChange(listener: (touched: boolean) => void): () => void {
        return this[SUBSCRIBER_TOUCHED].listen(listener);
    }
}

class ObjectPointer extends AbstractPointer<Record<string, any>> {
    public declare readonly form: JsonFormObject;
    public readonly properties!: Record<string, Pointer>;

    private [FORM_PROPERTIES]!: JsonFormProperty[];
    private [PROXY_VALUE]!: Record<string, any>;

    // Simplify TS types (allow usage of non-null operator)
    public readonly items: undefined = undefined;
    public readonly add: undefined = undefined;
    public readonly remove: undefined = undefined;

    public constructor(options: PointerOptions<Record<string, any>>) {
        super(options);
        if (options.form.type !== JsonFormType.object) {
            throw new Error('Only "object" form could be passed into ObjectPointer');
        }
        exposeDescriptor(this, 'value');
    }

    private updateProperty(name: string, value: any): void {
        // Trigger public setter
        this.value = { ...this[VALUE], [name]: value };
    }

    private computeProperties(): void {
        const properties = this.form.getProperties(this[VALUE], true);
        if (this[FORM_PROPERTIES] === properties) {
            return;
        }
        this[FORM_PROPERTIES] = properties;
        this[INTERNAL].properties = {};

        if (this.validation) {
            Object.defineProperty(this.form, IS_VALID_FNC_PROP_KEY_NAME, {
                value: ((value) => {
                    const formValue = this.getFormControllerValue();
                    const updatedFormValue = getUpdatedFormValue(
                        this.valuePath,
                        formValue,
                        value
                    );
                    const validationResult = validateWithValidation(
                        updatedFormValue,
                        value,
                        this.validation
                    );
                    return validationResult;
                }) as JsonFormValidator,
                enumerable: true,
                configurable: true,
            });
        }

        for (const property of properties) {
            const propertyValidation = (this.validation as FormControllerObjectFnc)?.[property.name];
            let nextPointerValidation;

            if (typeof propertyValidation === 'function') {
                Object.defineProperty(property.form, IS_VALID_FNC_PROP_KEY_NAME, {
                    value: ((value) => {
                        const formValue = this.getFormControllerValue();
                        return propertyValidation(formValue, value);
                    }) as JsonFormValidator,
                    enumerable: true,
                    configurable: true,
                });
            } else {
                nextPointerValidation = propertyValidation;
            }

            Object.defineProperty(this[INTERNAL].properties, property.name, {
                enumerable: true,
                configurable: true,
                value: createPointer({
                    depth: this.depth + 1,
                    id: `${this.id}_${property.name}`,
                    disabled: this.disabled,
                    touched: this.touched,
                    form: property.form,
                    required: property.required,
                    value: this[VALUE]?.[property.name],
                    onChange: (value: any) => this.updateProperty(property.name, value),
                    deletable: false,
                    validation: nextPointerValidation,
                    getFormControllerValue: this.getFormControllerValue,
                    valuePath: [ ...this.valuePath, property.name ],
                }),
            });
        }
    }

    private delegateToChildren(): void {
        for (const name of Object.keys(this.properties)) {
            this.properties[name]!.internalUpdate(this[VALUE]?.[name]);
            this.properties[name]!.internalSetParentDisabled(this.disabled);
            this.properties[name]!.internalSetParentTouched(this.touched);
        }
    }

    protected override [AFTER_INTERNAL_UPDATE](): void {
        const properties = this.form.getProperties(this[VALUE], true);
        this.computeProperties();
        this[PROXY_VALUE] = Object.defineProperties({}, properties.reduce((descriptors, property) => ({
            ...descriptors,
            [property.name]: {
                enumerable: (
                    this[VALUE][property.name] !== undefined &&
                    property.name in this[VALUE]
                ),
                configurable: true,
                get: () => this.properties[property.name]?.value,
                set: (value: any) => this.updateProperty(property.name, value),
            },
        }), {}));
        this.delegateToChildren();
    }

    protected override [AFTER_INTERNAL_DISABLE_UPDATE](): void {
        this.delegateToChildren();
    }

    protected override [AFTER_INTERNAL_TOUCHED_UPDATE](): void {
        this.delegateToChildren();
    }

    public override get value(): Record<string, any> {
        return this[PROXY_VALUE];
    }

    public override set value(value: Record<string, any>) {
        this[LISTENER](value);
    }
}

class ArrayPointer extends AbstractPointer<any[]> {
    public declare readonly form: JsonFormArray;
    public readonly items!: Pointer[];

    protected [PROXY_VALUE]!: any[];

    // Simplify TS types (allow usage of non-null operator)
    public readonly properties: undefined = undefined;

    public constructor(options: PointerOptions<any[]>) {
        super(options);
        if (options.form.type !== JsonFormType.array) {
            throw new Error('Only "array" form could be passed into ArrayPointer');
        }
        exposeDescriptor(this, 'value');
    }

    public add(item?: any): void {
        const currentValue = this[VALUE] || [];
        if (currentValue.length >= (this.form.schema.maxItems || Infinity)) {
            return;
        }
        // Trigger public setter
        this.value = [ ...currentValue, item ];
    }

    public remove(index: number): void {
        const currentValue = this[VALUE] || [];
        if (isNaN(index) || index < 0 || index >= currentValue.length) {
            return;
        }
        // Trigger public setter
        this.value = [
            ...currentValue.slice(0, index),
            ...currentValue.slice(index + 1),
        ];
    }

    private internalReplaceItem(index: number, item: any): void {
        const currentValue = this[VALUE] || [];
        if (index < 0 || currentValue.length <= index) {
            return;
        }
        // Trigger public setter
        this.value = [
            ...currentValue.slice(0, index),
            item,
            ...currentValue.slice(index + 1),
        ];
    }

    private computeItems(): void {
        if (!this.items) {
            this[INTERNAL].items = [];
        }
        const expectedLength = this[VALUE]?.length || 0;
        const prevLength = this.items.length;

        // Remove items if there is too many of them
        if (this.items.length > expectedLength) {
            this[INTERNAL].items = this.items.slice(0, expectedLength);
        }

        if (this.validation) {
            Object.defineProperty(this.form, IS_VALID_FNC_PROP_KEY_NAME, {
                value: ((value) => {
                    const formValue = this.getFormControllerValue();
                    const validationResult = validateWithValidation(
                        formValue,
                        value,
                        this.validation
                    );
                    return validationResult;
                }) as JsonFormValidator,
                enumerable: true,
                configurable: true,
            });
        }

        // Add new items if there are too low number of them
        if (this.items.length < expectedLength) {
            const nextItems = this.items.slice();
            for (let i = this.items.length; i < expectedLength; i++) {
                const internalReplaceItem = this.internalReplaceItem.bind(this, i);

                if (typeof this.validation === 'function') {
                    Object.defineProperty(this.form.item, IS_VALID_FNC_PROP_KEY_NAME, {
                        value: ((value) => {
                            const formValue = this.getFormControllerValue();
                            return (this.validation as FormControllerValidationFnc)(formValue, value);
                        }) as JsonFormValidator,
                        enumerable: true,
                        configurable: true,
                    });
                }

                nextItems[i] = createPointer({
                    depth: this.depth + 1,
                    id: `${this.id}_${i}`,
                    disabled: this.disabled,
                    touched: this.touched,
                    form: this.form.item,
                    required: this.required && i < (this.form.schema.minItems || Infinity),
                    value: this[VALUE]?.[i],
                    onChange: (value: any) => internalReplaceItem(value),
                    deletable: false,
                    validation: this.validation,
                    getFormControllerValue: this.getFormControllerValue,
                    valuePath: [ ...this.valuePath, `${i}` ],
                });
            }
            this[INTERNAL].items = nextItems;
        }

        // Update previous items (the newly created have already up to date value
        for (let i = 0; i < Math.min(prevLength, expectedLength); i++) {
            this.items[i]!.internalUpdate(this[VALUE]?.[i]);
        }
    }

    protected override [AFTER_INTERNAL_UPDATE](): void {
        this.computeItems();
        this[PROXY_VALUE] = this.items.map((item) => item.value);
    }

    protected override [AFTER_INTERNAL_DISABLE_UPDATE](): void {
        for (const item of this.items) {
            item.internalSetParentDisabled(this.disabled);
        }
    }

    protected override [AFTER_INTERNAL_TOUCHED_UPDATE](): void {
        for (const item of this.items) {
            item.internalSetParentTouched(this.touched);
        }
    }

    public override get value(): any[] {
        return this[PROXY_VALUE];
    }

    public override set value(value: any[]) {
        this[LISTENER](value);
    }
}

class PrimitivePointer extends AbstractPointer<any> {
    public declare readonly form: Exclude<JsonForm, JsonFormObject | JsonFormArray>;

    // Simplify TS types (allow usage of non-null operator)
    public readonly properties: undefined = undefined;
    public readonly items: undefined = undefined;
    public readonly add: undefined = undefined;
    public readonly remove: undefined = undefined;

    public constructor(options: PointerOptions<any>, validation?: FormControllerValidation) {
        super(options);
        if (options.form.type === JsonFormType.array || options.form.type === JsonFormType.object) {
            throw new Error('Only primitive form could be passed into PrimitivePointer');
        }
    }
}

export class FormController {
    public readonly form: JsonForm;
    public readonly structure: Pointer;

    private [DISABLED]: boolean = false;
    private [TOUCHED]: boolean = false;

    public constructor(
        form: JsonForm,
        initialValue?: any,
        validation?: FormControllerValidation
    ) {
        this.form = form;

        this.structure = createPointer({
            depth: 0,
            id: randomString(),
            disabled: this.disabled,
            touched: this.touched,
            deletable: false,
            required: true,
            value: this.form.getValue(initialValue, true),
            form: this.form,
            onChange: (value: any) => {
                this.value = value;
            },
            validation,
            getFormControllerValue: () => this.value,
            valuePath: [],
        });

        exposeDescriptor(this, 'value');
        exposeDescriptor(this, 'disabled');
        exposeDescriptor(this, 'touched');
    }

    public get disabled(): boolean {
        return this[DISABLED];
    }

    public set disabled(disabled: boolean) {
        this[DISABLED] = disabled;
        this.structure.internalSetParentDisabled(this[DISABLED]);
    }

    public get touched(): boolean {
        return this[TOUCHED];
    }

    public set touched(touched: boolean) {
        this[TOUCHED] = touched;
        this.structure.internalSetParentTouched(this[TOUCHED]);
    }

    public get valid(): boolean {
        return this.structure.valid;
    }

    public get value(): any {
        return this.structure?.value;
    }

    public set value(value: any) {
        this.structure.internalUpdate(this.form.getValue(value, true));
    }

    public onChange(listener: (data: PointerState<any>) => void): () => void {
        return this.structure.onChange(listener);
    }

    public onValueChange(listener: (value: any) => void): () => void {
        return this.structure.onValueChange(listener);
    }

    public onDisabledChange(listener: (disabled: boolean) => void): () => void {
        return this.structure.onDisabledChange(listener);
    }

    public onTouchedChange(listener: (touched: boolean) => void): () => void {
        return this.structure.onTouchedChange(listener);
    }
}
