import isEqual from 'lodash/isEqual';
import { GlsCheck } from '@evidentid/gls-service-api-client/types';
import Question from '@evidentid/ido-lib/interfaces/Question';
import type { GlsBasicCaseData } from '@/services/getGlsSessionInterview';
import UserData from '@/interfaces/UserData';
import { ValuesMap } from '@/store/interfaces/IdoState';

/**
 * Build automatic submission values,
 * when the case could proceed without any User's input.
 *
 * Otherwise, just return null,
 * so User may provide own values.
 */
export function buildImmediateGlsValues(
    caseData: GlsBasicCaseData,
    userData: UserData,
    questions: Question[],
): ValuesMap | null {
    // Fieldworker-only case
    if (questions.length === 1 && isEqual(caseData.requiredChecks, [ GlsCheck.fieldWorkerCriminal ])) {
        return {
            [questions[0].attrType]: {
                list: [ userData.email ],
                confirmed: true,
            },
        };
    }

    return null;
}
