const amBestRatings = [
    'A++',
    'A+',
    'A',
    'A-',
    'B++',
    'B+',
    'B',
    'B-',
    'C++',
    'C+',
    'C',
    'C-',
    'D',
    'E',
    'F',
    'S',
    'NR',
];

export default amBestRatings;
