import noop from 'lodash/noop';

export enum BrokerFormModals {
    none = 'none',
    brokerSubmission = 'brokerSubmission',
    coiUpload = 'coiUpload',
    coverageFieldsToComplete = 'coverageFieldsToComplete',
    coveragesToComplete = 'coveragesToComplete',
    disableCoverage = 'disableCoverage',
    removeCoverage = 'removeCoverage',
    removeInsurer = 'removeInsurer',
}

export interface BrokerFormModalConfiguration {
    modal: BrokerFormModals;
    open: boolean;
    coverage: string;
    onModalConfirm: (() => void);
    onModalCancel: (() => void);
}

export function getDefaultModalConfiguration(): BrokerFormModalConfiguration {
    return {
        modal: BrokerFormModals.none,
        open: false,
        coverage: '',
        onModalConfirm: noop,
        onModalCancel: noop,
    };
}
