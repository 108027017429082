export enum Acord25Field {
    effectiveDate = 'effective_date',
    expirationDate = 'expiration_date',
    policyNumber = 'policy_number',
}

export enum CertificateField {
    insured = 'insured',
    currency = 'currency',
}

export enum InsurerField {
    carrierName = 'carrier_name',
    carrierNaicNumber = 'carrier_naic_number',
    amBestRating = 'am_best_rating',
}

export enum TypeOfInsuranceField {
    autoMobilesCovered = 'automobiles_covered',
    occurrenceOrClaimsMade = 'occurrence_or_claims_made',
    generalAggregateLimitAppliesPer = 'general_aggregate_limit_applies_per',
}

export enum LimitField {
    accidentalDeath = 'accidental_death',
    accidentalDismemberment = 'accidental_dismemberment',
    accidentalMedicalExpense = 'accidental_medical_expense',
}

export enum HiddenField {
    status = 'status',
}
