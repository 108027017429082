import findLastIndex from 'lodash/findLastIndex';
import { IdoRequestStatus, RelyingPartyRequestStatusType } from './getIdoRequestStatus';
import Identity from './interfaces/Identity';
import * as requestMessages from './requestMessages';
import {
    IdoRequestStatusTimelineEntry,
    IdoRequestStatusTimelineEntryStatus,
} from './interfaces/IdoRequestStatus';
import ensureAbsoluteUrl from '@evidentid/universal-framework/ensureAbsoluteUrl';
import { RequestStatusType } from './interfaces/RequestStatusType';

export interface RequestStatusMetadata extends IdoRequestStatus {
    rpName: string;
}

export interface RequestStatusData {
    status: RequestStatusType | null;
    message: string | null;

    timeline: IdoRequestStatusTimelineEntry[];

    description: string;
    nextStepsDescription: string;
    nextStepsLink: string | null;
    nextStepsLinkTitle: string | null;

    supportEmail: string | null;
    supportPhone: string | null;
    supportLink: string | null;
    supportTitle: string | null;

    metadata: RequestStatusMetadata;
}

function buildTimelineForStatus(status: IdoRequestStatus): IdoRequestStatusTimelineEntry[] {
    const timeline = [
        {
            status: IdoRequestStatusTimelineEntryStatus.success,
            title: requestMessages.timelineMessages.request.title,
            description: requestMessages.timelineMessages.request.description,
        },
        {
            status: IdoRequestStatusTimelineEntryStatus.ongoing,
            title: requestMessages.timelineMessages.submission.title,
        },
        {
            status: IdoRequestStatusTimelineEntryStatus.disabled,
            title: requestMessages.timelineMessages.processing.title,
        },
        {
            status: IdoRequestStatusTimelineEntryStatus.disabled,
            title: requestMessages.timelineMessages.complete.title,
        },
    ];

    switch (status.type) {
        case RelyingPartyRequestStatusType.new:
            break;
        case RelyingPartyRequestStatusType.timeout:
            timeline[1].status = IdoRequestStatusTimelineEntryStatus.error;
            timeline[1].description = requestMessages.timelineMessages.timeout.description;
            break;
        case RelyingPartyRequestStatusType.processing:
            timeline[1].status = IdoRequestStatusTimelineEntryStatus.success;
            timeline[1].description = requestMessages.timelineMessages.submission.description;
            timeline[2].status = IdoRequestStatusTimelineEntryStatus.ongoing;
            timeline[2].description = requestMessages.timelineMessages.processing.ongoing;
            break;
        case RelyingPartyRequestStatusType.maxAttemptsExceeded:
            timeline[1].status = IdoRequestStatusTimelineEntryStatus.error;
            timeline[1].description = requestMessages.timelineMessages.maxAttemptsExceeded.description;
            break;
        case RelyingPartyRequestStatusType.canceled:
            // Mark as submitted, if it was done
            if (status.submittedAt) {
                timeline[1].status = IdoRequestStatusTimelineEntryStatus.success;
                timeline[1].description = requestMessages.timelineMessages.submission.description;
            }

            // Mark as processed, if it was done
            if (status.completedAt) {
                timeline[2].status = IdoRequestStatusTimelineEntryStatus.success;
                timeline[2].description = requestMessages.timelineMessages.processing.description;
            }

            // Include information about cancellation to next entry
            const lastEntryIndex = findLastIndex(
                timeline,
                (entry) => entry.status === IdoRequestStatusTimelineEntryStatus.success,
            );
            const nextEntry = timeline[lastEntryIndex + 1];
            nextEntry.status = IdoRequestStatusTimelineEntryStatus.error;
            nextEntry.description = requestMessages.timelineMessages.canceled.description;
            break;
        case RelyingPartyRequestStatusType.complete:
            timeline[1].status = IdoRequestStatusTimelineEntryStatus.success;
            timeline[1].description = requestMessages.timelineMessages.submission.description;
            timeline[2].status = IdoRequestStatusTimelineEntryStatus.success;
            timeline[2].description = requestMessages.timelineMessages.processing.description;
            timeline[3].status = IdoRequestStatusTimelineEntryStatus.success;
            timeline[3].description = requestMessages.timelineMessages.complete.description;
            break;
    }

    return timeline;
}

export default function getIdoRequestStatusData(
    status: IdoRequestStatus,
    identity: Identity | null,
): RequestStatusData {
    const rp = identity || ({} as Identity);

    // @ts-ignore: status type may not have a message
    const idoStatusMessage = (rp.idoStatusMessages && rp.idoStatusMessages[status.type]) || {};

    // Build regular status data
    const statusData = {
        status: requestMessages.alertMessages[status.type]?.type || null,
        message: requestMessages.alertMessages[status.type]?.message || null,

        timeline: buildTimelineForStatus(status),

        description: requestMessages.instructions[status.type],

        nextStepsDescription: idoStatusMessage.msg || requestMessages.nextStepsMessages[status.type](status),
        nextStepsLink: idoStatusMessage.url ? ensureAbsoluteUrl(idoStatusMessage.url) : null,
        nextStepsLinkTitle: idoStatusMessage.urlTitle || null,

        supportEmail: rp.supportContactInfo?.email || null,
        supportPhone: rp.supportContactInfo?.phone || null,
        supportLink: rp.supportContactInfo?.url ? ensureAbsoluteUrl(rp.supportContactInfo?.url) : null,
        supportTitle: rp.supportContactInfo?.urlTitle || '{{rpName}} support page',

        metadata: { ...status, rpName: rp.displayName },
    };

    // Show support ticket link in "next steps", when request is processed too long
    if (status.daysProcessing >= requestMessages.longProcessingNextStep.minDaysProcessing) {
        statusData.nextStepsLink = requestMessages.longProcessingNextStep.nextStepsLink;
        statusData.nextStepsLinkTitle = requestMessages.longProcessingNextStep.nextStepsLinkTitle;
    }

    return statusData;
}
