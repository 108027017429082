import noop from 'lodash/noop';
import delay from '@evidentid/universal-framework/delay';
import {
    FaceTecFaceScanProcessor,
    FaceTecFaceScanResultCallback,
    FaceTecSDK,
    FaceTecSession,
    FaceTecSessionStatus,
    FaceTecSessionResult,
    LivenessCheckResult,
    LivenessVerificationStatus,
    VerifyLiveness,
} from '../types';
import { buildFailedLivenessCheckResult } from './buildFailedLivenessCheckResult';
import { AbortError } from './AbortError';

export type OnCompleteCallback = (result: LivenessCheckResult) => void;

const LivenessAborted = 'aborted';

export interface LivenessCheckProcessorOptions {
    verify?: VerifyLiveness | null;
    maxTries?: number;
    onRetry?: (triesCount: number) => any;
}

export class LivenessCheckProcessor implements FaceTecFaceScanProcessor {
    public readonly session: FaceTecSession;
    private readonly onComplete: OnCompleteCallback;
    private readonly sdk: FaceTecSDK;
    private readonly verify: VerifyLiveness | null;
    private readonly onRetry: (triesCount: number) => any;
    private readonly maxTries: number;
    private finished: boolean = false;
    private tries = 0;

    public constructor(
        sdk: FaceTecSDK,
        sessionToken: string,
        onComplete: OnCompleteCallback,
        options?: LivenessCheckProcessorOptions,
    ) {
        this.sdk = sdk;
        this.onComplete = onComplete;
        this.session = new this.sdk.FaceTecSession(this, sessionToken);
        this.verify = options?.verify || null;
        this.onRetry = options?.onRetry || noop;

        const maxTries = options?.maxTries ? options?.maxTries : Infinity;
        this.maxTries = maxTries >= 1 ? maxTries : Infinity;
    }

    public cancel(): void {
        // Ignore when it was already finished
        if (this.finished) {
            return;
        }

        // Get FaceTec DOM elements
        const container = document.querySelector<HTMLDivElement>('#DOM_FT_PRIMARY_TOPLEVEL_mainContainer');
        const cancelElement = document.querySelector<HTMLButtonElement>('#DOM_FT_cancelButtonElement');

        // Inform locally about failure
        this.finished = true;
        this.onComplete(buildFailedLivenessCheckResult(FaceTecSessionStatus.ProgrammaticallyCancelled));

        // Do nothing if the FaceTec is not there
        if (!container) {
            return;
        }

        // Hide container immediately
        container.style.display = 'none';

        // Cancel each consecutive FaceTec operation,
        // until the FaceTec container is removed completely.
        (async () => {
            while (container.parentNode) {
                cancelElement?.dispatchEvent(new Event('mousedown'));
                await delay(500);
            }
        })();
    }

    public async processSessionResultWhileFaceTecSDKWaits(
        sessionResult: FaceTecSessionResult,
        faceScanResultCallback: FaceTecFaceScanResultCallback,
    ): Promise<void> {
        if (this.finished) {
            return;
        }

        // Scan has not been performed - i.e. user cancellation, timeout
        if (sessionResult.status !== FaceTecSessionStatus.SessionCompletedSuccessfully) {
            this.finished = true;
            faceScanResultCallback.cancel();
            this.onComplete(buildFailedLivenessCheckResult(sessionResult.status));
            return;
        }

        // Extract data
        const sessionId = sessionResult.sessionId!;
        const faceScan = sessionResult.faceScan!;
        const auditTrail = sessionResult.auditTrail[0];
        const lowQualityAuditTrail = sessionResult.lowQualityAuditTrail[0];
        const auditTrailDataUrl = `data:image/jpeg;base64,${auditTrail}`;
        const lowQualityAuditTrailDataUrl = `data:image/jpeg;base64,${lowQualityAuditTrail}`;

        // Verify it if it's expected
        if (this.verify) {
            // Find the verification status
            const status = await this.verify({ faceScan, auditTrail, sessionId })
                .catch((error) => {
                    if (error instanceof AbortError) {
                        return LivenessAborted;
                    } else {
                        console.error('Something went wrong during Liveness status verification', error);
                        return LivenessVerificationStatus.internalError;
                    }
                })
                .then((result) => {
                    if (result !== LivenessAborted && !Object.values(LivenessVerificationStatus).includes(result)) {
                        console.error('Invalid value detected for Liveness status verification', result);
                        return LivenessVerificationStatus.internalError;
                    }
                    return result;
                });

            // Handle the failure
            if (status === LivenessAborted) {
                // Ignore it when user refreshed page or aborted request specifically
                this.finished = true;
                faceScanResultCallback.cancel();
                this.onComplete(buildFailedLivenessCheckResult(FaceTecSessionStatus.ProgrammaticallyCancelled));
                return;
            } else if (status === LivenessVerificationStatus.internalError) {
                // Ignore when there was internal problem, don't punish User for our mistakes
                console.error('Internal server error during Liveness status verification');
            } else if (status !== LivenessVerificationStatus.success) {
                // Count tries
                this.tries++;

                // Retry, when it is clear that there was some problem
                if (this.tries < this.maxTries) {
                    this.onRetry(this.tries);
                    faceScanResultCallback.retry(this.sdk.FaceTecRetryScreen.ShowStandardRetryScreenIfRejected);
                    return;
                }
            }
        }

        // Mark as succeed
        this.finished = true;
        faceScanResultCallback.succeed();

        // Expose information about result
        this.onComplete({
            status: FaceTecSessionStatus.SessionCompletedSuccessfully,
            auditTrailImage: auditTrailDataUrl,
            lowQualityAuditTrailImage: lowQualityAuditTrailDataUrl,
            userAgent: this.sdk.createFaceTecAPIUserAgentString(sessionId),
            sessionId,
            faceScan,
        });
    }

    public onFaceTecSDKCompletelyDone(): void {
        // Do nothing, as it's already informed through this.onComplete
    }
}
