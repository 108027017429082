export enum CoverageTypeState {
    notAvailable = 'notAvailable',
    notRenewing = 'notRenewing',
    notApplicable = 'notApplicable',
    tooExpensive = 'tooExpensive',
    other = 'other',
    declinedToShare = 'declinedToShare',
    provided = 'provided',
}

export enum CoverageFieldState {
    notApplicable = 'notApplicable',
    tooExpensive = 'tooExpensive',
    other = 'other',
    declinedToShare = 'declinedToShare',
    notOnThePolicy = 'notOnThePolicy',
    provided = 'provided',
}

export type CoverageTypeRejectionReason = Exclude<CoverageTypeState, CoverageTypeState.provided>;
export type CoverageFieldRejectionReason = Exclude<CoverageFieldState, CoverageFieldState.provided>;
